/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useTranslation } from "react-i18next";
import RadioGroup from "@mui/material/RadioGroup";

import { SelectAuthenticationMethodProps } from "../types";

import Radio from "../../../../../../../DesignComponents/Radio";
import Badge, {
    BADGE_COLORS
} from "../../../../../../../DesignComponents/Badge";

import { AUTHENTICATION_TYPES } from "../../../../../../../../shared";

const SelectAuthenticationType = ({
    type,
    changeType
}: SelectAuthenticationMethodProps) => {
    const { t } = useTranslation();

    return (
        <RadioGroup
            value={type}
            onChange={e => changeType(e.target.value as AUTHENTICATION_TYPES)}
            sx={{
                rowGap: "16px"
            }}
        >
            <Radio
                value={AUTHENTICATION_TYPES.Authenticator}
                label={
                    <div
                        css={css({
                            display: "flex",
                            alignItems: "center",
                            flexWrap: "wrap",
                            rowGap: "4px",
                            marginTop: "-2px",
                            marginBottom: "4px",

                            "& > span": {
                                display: "inline-block",
                                marginRight: "10px"
                            }
                        })}
                    >
                        <span>
                            {t("Security##2FA##title##authentication app")}
                        </span>

                        <Badge color={BADGE_COLORS.Info}>
                            {t(
                                "Security##2FA##select authentication type##recommended"
                            )}
                        </Badge>
                    </div>
                }
                info={t(
                    "Security##2FA##select authentication type##authentication app description"
                )}
            />

            <Radio
                value={AUTHENTICATION_TYPES.Email}
                label={
                    <div
                        css={css({
                            marginBottom: "4px"
                        })}
                    >
                        {t("Security##2FA##select authentication type##email")}
                    </div>
                }
                info={t(
                    "Security##2FA##select authentication type##email description"
                )}
            />
        </RadioGroup>
    );
};

export { SelectAuthenticationType };
