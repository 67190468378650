/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import LegacyDevices from "../LegacyDevices";
import { AddDeviceProps, DeviceFromFileInputData } from "../types";

import CompanyAndGroupDropdowns from "../../components/CompanyAndGroupDropdowns";
import { CompanyGroupPayloadProps, FILE_NAME } from "../../types";

import SampleTemplate from "../../../../../DesignComponents/SampleTemplate";
import Dropzone from "../../../../../Dropzone";

import DropzoneContext from "../../../../../../context/dropzone/dropzoneContext";
import { ENDPOINTS, FILE_NAME_MAX_LENGTH } from "../../../../../../shared";

const AddDeviceFromFile = ({ setDevicePayload }: AddDeviceProps) => {
    const { file, lineCount } = useContext(DropzoneContext);

    const { t } = useTranslation();

    const [deviceInfo, setDeviceInfo] = useState<DeviceFromFileInputData>({
        company: null,
        group: null,
        importAsLegacy: false
    });

    const { company, group, importAsLegacy } = deviceInfo;

    useEffect(() => {
        const isReady = company !== null && file && lineCount > 0;

        if (isReady) {
            const getFormData = () => {
                const formData = new FormData();

                formData.append("file", file as Blob);
                formData.append("company_id", String(company));
                formData.append("import_as_legacy", String(importAsLegacy));
                group !== null && formData.append("group_id", String(group));

                return formData;
            };

            const data = {
                resource: `${ENDPOINTS.Devices}/import`,
                payload: getFormData()
            };

            setDevicePayload(data);
        } else {
            setDevicePayload(null);
        }
        // eslint-disable-next-line
    }, [company, group, importAsLegacy, file]);

    const handleCompanyAndGroupChange = (
        data: CompanyGroupPayloadProps | null
    ) =>
        setDeviceInfo({
            ...deviceInfo,
            company: data?.company ?? null,
            group: data?.group ?? null
        });

    const handleImportAsLegacyChange = (value: boolean) =>
        setDeviceInfo({ ...deviceInfo, importAsLegacy: value });

    return (
        <div
            css={css({
                marginTop: "16px",
                marginBottom: "48px",

                "> div:nth-of-type(2)": {
                    marginTop: file === null ? 0 : "45px"
                }
            })}
        >
            <Dropzone
                supportedFormats={[".csv"]}
                countCsv
                maxFileNameLength={FILE_NAME_MAX_LENGTH}
                tooltipText={`
                    ${t("Dropzone##file syntax")} 
                    ${t("Dialog##imei")}*, 
                    ${t("Dialog##sn")}*, 
                    ${t("Dialog##description")}
                `}
            />

            <SampleTemplate fileName={FILE_NAME.SampleAddDeviceList} />

            <CompanyAndGroupDropdowns
                isCompanyRequired
                isRootRequired
                setPayload={handleCompanyAndGroupChange}
            />

            <LegacyDevices toggleValue={handleImportAsLegacyChange} />
        </div>
    );
};
export default AddDeviceFromFile;
