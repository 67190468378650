/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useTranslation } from "react-i18next";

import {
    GetEmailCode,
    GetSecretKey,
    SelectAuthenticationType,
    SendOtpCodeToEnable
} from "./content";
import { EnableTwoFactorAuthenticationProps } from "./types";

import { SendEmailCodeDescription } from "../components";

import Dialog from "../../../../../../DesignComponents/Dialog";
import Button, {
    BUTTON_COLORS,
    BUTTON_SIZES,
    BUTTON_VARIANTS
} from "../../../../../../DesignComponents/Button";
import { OtpInputField } from "../../../../../../Auth/shared";

import {
    ENABLE_AUTHENTICATION_STEPS,
    isAuthenticatorType,
    maxDialogWidth,
    useEnableTwoFactorAuthentication,
    useMediaQueries
} from "../../../../../../../shared";

const EnableTwoFactorAuthentication = ({
    isOpen,
    close,
    isRequired
}: EnableTwoFactorAuthenticationProps) => {
    const {
        authType,
        error,
        isLoading,
        otpCode,
        secretKey,
        step,
        changeAuthenticationType,
        changeOtpCode,
        changeStep,
        getEmailCodeSubmit,
        getSecretKeySubmit,
        handleClose,
        sendOtpCodeSubmit,
        setError,
        setOtpCode,
        setSecretKey
    } = useEnableTwoFactorAuthentication();

    const { t } = useTranslation();
    const { fromMd } = useMediaQueries();

    const steps = {
        [ENABLE_AUTHENTICATION_STEPS.SelectMethod]: {
            hasSeparators: true,
            title: t("Security##2FA##select authentication type##title##modal"),
            description: t(
                "Security##2FA##select authentication type##description modal"
            ),
            secondaryBtn: isRequired ? null : t("Button##close"),
            submitBtn: t("Button##next"),
            isSubmitBtnDisabled: authType === null,
            content: (
                <SelectAuthenticationType
                    type={authType}
                    changeType={changeAuthenticationType}
                />
            ),
            onSecondaryBtnClick: () => handleClose(close),
            onSubmit: () => {
                const nextStep = isAuthenticatorType(authType)
                    ? ENABLE_AUTHENTICATION_STEPS.GetSecretKey
                    : ENABLE_AUTHENTICATION_STEPS.GetEmailCode;

                changeStep(nextStep);
            }
        },
        [ENABLE_AUTHENTICATION_STEPS.GetSecretKey]: {
            hasSeparators: true,
            title: t("Security##2FA##title##authentication app"),
            description: t("Security##2FA##get secret code##description"),
            secondaryBtn: t("Button##back"),
            submitBtn: t("Button##continue"),
            isSubmitBtnDisabled: false,
            content: <GetSecretKey />,
            onSecondaryBtnClick: () =>
                changeStep(ENABLE_AUTHENTICATION_STEPS.SelectMethod),
            onSubmit: getSecretKeySubmit
        },
        [ENABLE_AUTHENTICATION_STEPS.GetEmailCode]: {
            hasSeparators: true,
            title: t("Security##2FA##title##authentication email"),
            description: "",
            secondaryBtn: t("Button##back"),
            submitBtn: t("Button##send code"),
            isSubmitBtnDisabled: false,
            content: <GetEmailCode />,
            onSecondaryBtnClick: () =>
                changeStep(ENABLE_AUTHENTICATION_STEPS.SelectMethod),
            onSubmit: getEmailCodeSubmit
        },
        [ENABLE_AUTHENTICATION_STEPS.SendOtpCode]: {
            hasSeparators: true,
            title: t("Security##2FA##title##authentication app"),
            description: "",
            secondaryBtn: t("Button##back"),
            submitBtn: t("Button##verify"),
            isSubmitBtnDisabled: otpCode.length < 6 || authType === null,
            content: (
                <SendOtpCodeToEnable
                    secretKey={secretKey}
                    error={error}
                    isLoading={isLoading}
                    changeCode={changeOtpCode}
                />
            ),
            onSecondaryBtnClick: () => {
                changeStep(ENABLE_AUTHENTICATION_STEPS.GetSecretKey);
                setOtpCode("");
                setError(null);
            },
            onSubmit: sendOtpCodeSubmit
        },
        [ENABLE_AUTHENTICATION_STEPS.SendEmailCode]: {
            hasSeparators: true,
            title: t("Security##2FA##title##authentication email"),
            description: <SendEmailCodeDescription />,
            secondaryBtn: t("Button##back"),
            submitBtn: t("Button##verify"),
            isSubmitBtnDisabled: otpCode.length < 6 || authType === null,
            content: (
                <OtpInputField
                    isLoading={isLoading}
                    error={error}
                    onChange={changeOtpCode}
                    label={t(
                        "Security##2FA##send email code##verification code"
                    )}
                    isRequired
                />
            ),
            onSecondaryBtnClick: () => {
                changeStep(ENABLE_AUTHENTICATION_STEPS.GetEmailCode);
                setError(null);
            },
            onSubmit: sendOtpCodeSubmit
        },
        [ENABLE_AUTHENTICATION_STEPS.SuccessMessage]: {
            hasSeparators: false,
            title: t("Security##2FA##success message##title modal"),
            description: t("Security##2FA##success message##title"),
            secondaryBtn: isRequired ? null : t("Button##close"),
            submitBtn: isRequired ? t("Button##open fota web") : null,
            isSubmitBtnDisabled: false,
            content: null,
            onSecondaryBtnClick: () => handleClose(close),
            onSubmit: () => handleClose(close)
        }
    };

    const hasSeparators = steps[step].hasSeparators;
    const title = steps[step].title;
    const description = steps[step].description;
    const secondaryBtnName = steps[step].secondaryBtn;
    const submitBtnName = steps[step].submitBtn;
    const isDisabled = steps[step].isSubmitBtnDisabled;
    const content = steps[step].content;
    const onSecondaryBtnClick = steps[step].onSecondaryBtnClick;
    const onSubmit = steps[step].onSubmit;

    return (
        <Dialog
            hideBackdrop={isRequired}
            open={isOpen}
            close={isRequired ? undefined : () => handleClose(close)}
            isTitleSeparator={hasSeparators}
            isActionsSeparator={hasSeparators}
            extendToMaxWidth
            title={title}
            description={description}
            submit={onSubmit}
            TransitionProps={{
                onExited: () => {
                    changeAuthenticationType(null);
                    changeStep(ENABLE_AUTHENTICATION_STEPS.SelectMethod);
                    setSecretKey("");
                    setOtpCode("");
                    setError(null);
                }
            }}
            actions={
                <>
                    {secondaryBtnName && (
                        <Button
                            fullWidth
                            color={BUTTON_COLORS.Secondary}
                            size={BUTTON_SIZES.Normal}
                            variant={BUTTON_VARIANTS.TextOnly}
                            onClick={onSecondaryBtnClick}
                        >
                            {secondaryBtnName}
                        </Button>
                    )}

                    {submitBtnName && (
                        <Button
                            key={submitBtnName}
                            fullWidth
                            color={BUTTON_COLORS.Primary}
                            size={BUTTON_SIZES.Normal}
                            variant={BUTTON_VARIANTS.TextOnly}
                            type="submit"
                            disabled={isDisabled}
                            isLoading={isLoading}
                        >
                            {submitBtnName}
                        </Button>
                    )}
                </>
            }
            css={css(fromMd && maxDialogWidth)}
        >
            {content}
        </Dialog>
    );
};

export default EnableTwoFactorAuthentication;
