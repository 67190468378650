/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext, useState, ChangeEvent, MouseEvent } from "react";
import { useTranslation } from "react-i18next";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import PasswordValidation from "../../../../../../DesignComponents/PasswordValidation";
import Dialog from "../../../../../../DesignComponents/Dialog";
import InputField, {
    INPUT_FIELD_SIZES
} from "../../../../../../DesignComponents/InputField";
import {
    TOOLTIP_PLACEMENT,
    TooltipGeneral
} from "../../../../../../DesignComponents/Tooltips";
import DialogActions from "../../../../../../Actions/dialogs/components/DialogActions";
import { BUTTON_TYPES, PASSWORD_TYPES } from "../../../../../../Auth/types";

import ThemeContext from "../../../../../../../context/theme/themeContext";
import {
    DialogHandler,
    ENDPOINTS,
    maxDialogWidth,
    useApi,
    useMediaQueries
} from "../../../../../../../shared";

const ChangePasswordModal = ({ isOpen, close }: DialogHandler) => {
    const {
        colorsFacelift: { gray500, gray600 }
    } = useContext(ThemeContext);

    const { t } = useTranslation();
    const { postData, handleResponse } = useApi();
    const { fromMd } = useMediaQueries();

    const [isLoading, setIsLoading] = useState(false);
    const [isEnabled, setIsEnabled] = useState(false);

    const [password, setPassword] = useState({
        [PASSWORD_TYPES.Current]: "",
        [PASSWORD_TYPES.NewPsw]: "",
        [PASSWORD_TYPES.Repeat]: ""
    });

    const [showPassword, setShowPassword] = useState({
        [PASSWORD_TYPES.Current]: false,
        [PASSWORD_TYPES.NewPsw]: false,
        [PASSWORD_TYPES.Repeat]: false
    });

    const toggleDisable = (value: boolean) => setIsEnabled(value);

    const handleValueChange = (e: ChangeEvent<HTMLInputElement>) =>
        setPassword({ ...password, [e.target.name]: e.target.value });

    const togglePswVisibility = (
        e: MouseEvent<HTMLSpanElement>,
        name: PASSWORD_TYPES
    ) => {
        e.preventDefault();
        setShowPassword({ ...showPassword, [name]: !showPassword[name] });
    };

    const handleSubmit = async () => {
        try {
            setIsLoading(true);

            const response = await postData(
                `${ENDPOINTS.Users}/changePassword`,
                {
                    old_password: password[PASSWORD_TYPES.Current],
                    password: password[PASSWORD_TYPES.NewPsw]
                }
            );

            close();

            handleResponse(response);

            setPassword({
                [PASSWORD_TYPES.Current]: "",
                [PASSWORD_TYPES.NewPsw]: "",
                [PASSWORD_TYPES.Repeat]: ""
            });
        } catch (error) {
            handleResponse(error);
        }

        setIsLoading(false);
    };

    return (
        <Dialog
            data-testid="change-password-dialog"
            open={isOpen}
            close={close}
            isTitleSeparator
            isActionsSeparator
            title={t("Security##password##change password")}
            submit={handleSubmit}
            TransitionProps={{
                onExited: () => {
                    setPassword({
                        [PASSWORD_TYPES.Current]: "",
                        [PASSWORD_TYPES.NewPsw]: "",
                        [PASSWORD_TYPES.Repeat]: ""
                    });

                    setShowPassword({
                        [PASSWORD_TYPES.Current]: false,
                        [PASSWORD_TYPES.NewPsw]: false,
                        [PASSWORD_TYPES.Repeat]: false
                    });
                }
            }}
            actions={
                <DialogActions
                    onClose={close}
                    isLoading={isLoading}
                    isReadyToConfirm={isEnabled}
                    confirmationButtonText={t("Button##change")}
                    cancelButtonTestId="change-password-cancel-button"
                    confirmationButtonTestId="change-password-confirm-button"
                />
            }
            css={css([
                {
                    "form > div > div + div:not(:last-of-type)": {
                        marginTop: "16px"
                    },

                    "& .change-psw-visibility": {
                        display: "flex",
                        cursor: "pointer",
                        color: gray500,
                        transition:
                            "color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",

                        "&:hover": {
                            color: gray600
                        }
                    }
                },
                fromMd && maxDialogWidth
            ])}
        >
            <InputField
                data-testid="change-password-current-password-field"
                autoFocus
                fullWidth
                value={password[PASSWORD_TYPES.Current]}
                name={PASSWORD_TYPES.Current}
                labelLeft={t("Security##password##enter current password")}
                placeholder={t("General##enter here")}
                size={INPUT_FIELD_SIZES.Medium}
                type={
                    showPassword[PASSWORD_TYPES.Current] ? "text" : "password"
                }
                onChange={handleValueChange}
                iconRight={
                    <TooltipGeneral
                        disableTouchListener
                        placement={TOOLTIP_PLACEMENT.TopEnd}
                        title={
                            showPassword[PASSWORD_TYPES.Current]
                                ? t("Security##password##hide password")
                                : t("Security##password##show password")
                        }
                        css={css({
                            "& .MuiTooltip-tooltip": {
                                marginRight: "-6px"
                            }
                        })}
                    >
                        <span
                            onMouseDown={e =>
                                togglePswVisibility(e, PASSWORD_TYPES.Current)
                            }
                            className="change-psw-visibility"
                        >
                            {showPassword[PASSWORD_TYPES.Current] ? (
                                <VisibilityOff />
                            ) : (
                                <Visibility />
                            )}
                        </span>
                    </TooltipGeneral>
                }
            />

            <InputField
                data-testid="change-password-new-password-field"
                fullWidth
                value={password[PASSWORD_TYPES.NewPsw]}
                name={PASSWORD_TYPES.NewPsw}
                labelLeft={t("Security##password##enter new password")}
                placeholder={t("General##enter here")}
                size={INPUT_FIELD_SIZES.Medium}
                type={showPassword[PASSWORD_TYPES.NewPsw] ? "text" : "password"}
                onChange={handleValueChange}
                iconRight={
                    <TooltipGeneral
                        disableTouchListener
                        placement={TOOLTIP_PLACEMENT.TopEnd}
                        title={
                            showPassword[PASSWORD_TYPES.NewPsw]
                                ? t("Security##password##hide password")
                                : t("Security##password##show password")
                        }
                        css={css({
                            "& .MuiTooltip-tooltip": {
                                marginRight: "-6px"
                            }
                        })}
                    >
                        <span
                            onMouseDown={e =>
                                togglePswVisibility(e, PASSWORD_TYPES.NewPsw)
                            }
                            className="change-psw-visibility"
                        >
                            {showPassword[PASSWORD_TYPES.NewPsw] ? (
                                <VisibilityOff />
                            ) : (
                                <Visibility />
                            )}
                        </span>
                    </TooltipGeneral>
                }
            />

            <InputField
                data-testid="change-password-repeat-password-field"
                fullWidth
                value={password[PASSWORD_TYPES.Repeat]}
                name={PASSWORD_TYPES.Repeat}
                labelLeft={t("Security##password##repeat new password")}
                placeholder={t("General##enter here")}
                size={INPUT_FIELD_SIZES.Medium}
                type={showPassword[PASSWORD_TYPES.Repeat] ? "text" : "password"}
                onChange={handleValueChange}
                iconRight={
                    <TooltipGeneral
                        disableTouchListener
                        placement={TOOLTIP_PLACEMENT.TopEnd}
                        title={
                            showPassword[PASSWORD_TYPES.Repeat]
                                ? t("Security##password##hide password")
                                : t("Security##password##show password")
                        }
                        css={css({
                            "& .MuiTooltip-tooltip": {
                                marginRight: "-6px"
                            }
                        })}
                    >
                        <span
                            onMouseDown={e =>
                                togglePswVisibility(e, PASSWORD_TYPES.Repeat)
                            }
                            className="change-psw-visibility"
                        >
                            {showPassword[PASSWORD_TYPES.Repeat] ? (
                                <VisibilityOff />
                            ) : (
                                <Visibility />
                            )}
                        </span>
                    </TooltipGeneral>
                }
                css={css({
                    marginBottom: "24px"
                })}
            />

            <PasswordValidation
                name={BUTTON_TYPES.NavBarChange}
                currentPsw={password[PASSWORD_TYPES.Current]}
                newPsw={password[PASSWORD_TYPES.NewPsw]}
                repeatPsw={password[PASSWORD_TYPES.Repeat]}
                onRulesMet={toggleDisable}
            />
        </Dialog>
    );
};

export default ChangePasswordModal;
